import { Pipe, PipeTransform } from '@angular/core'
import { PickingListOrder } from '../picking-list.model'

@Pipe({
  name: 'pickingOrderWeightVolume',
})
export class PickingOrderWeightVolumePipe implements PipeTransform {
  transform(order: PickingListOrder): string {
    return `${order.estimatedWeight?.toFixed(2) ?? 'N/D'} kg / ${order.estimatedVolume?.toFixed(2) ?? 'N/D'} m²`
  }
}
