import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'

export interface Webhook extends Entity {
  /**
   * Webhook event type.
   */
  event: WebhookEvent
  /**
   * Target URL. Must start with "https://".
   */
  url: string
  /**
   * This is the key used to compute the signature (HMAC SHA256 Base64) header (x-orderpod-webhook-signature).
   */
  secret: string | undefined
  notificationEmails: string[] | undefined
  isDisabled: boolean | undefined
}

export enum WebhookCollection {
  order = 'ORDER',
  goodsReturn = 'GOODS_RETURN',
}

export enum WebhookCollectionStatus {
  canceled = 'CANCELED',
  confirmed = 'CONFIRMED',
  created = 'CREATED',
  deleted = 'DELETED',
  delivered = 'DELIVERED',
  dropshipped = 'DROPSHIPPED',
  handed = 'HANDED',
  packed = 'PACKED',
  parked = 'PARKED',
  pending = 'PENDING',
  processed = 'PROCESSED',
  processing = 'PROCESSING',
  shipped = 'SHIPPED',
  updated = 'UPDATED',
  returning = 'RETURNING',
  received = 'RECEIVED',
  closed = 'CLOSED',
}

export enum WebhookEvent {
  orderCanceled = 'ORDER_CANCELED',
  orderConfirmed = 'ORDER_CONFIRMED',
  orderCreated = 'ORDER_CREATED',
  orderDeleted = 'ORDER_DELETED',
  orderDelivered = 'ORDER_DELIVERED',
  orderDropshipped = 'ORDER_DROPSHIPPED',
  orderHanded = 'ORDER_HANDED',
  orderPacked = 'ORDER_PACKED',
  orderParked = 'ORDER_PARKED',
  orderPending = 'ORDER_PENDING',
  orderProcessed = 'ORDER_PROCESSED',
  orderProcessing = 'ORDER_PROCESSING',
  orderShipped = 'ORDER_SHIPPED',
  orderUpdated = 'ORDER_UPDATED',
  goodsReturnCanceled = 'GOODS_RETURN_CANCELED',
  goodsReturnClosed = 'GOODS_RETURN_CLOSED',
  goodsReturnReceived = 'GOODS_RETURN_RECEIVED',
  goodsReturnReturning = 'GOODS_RETURN_RETURNING',
  goodsReturnDeleted = 'GOODS_RETURN_DELETED',
  goodsReturnUpdated = 'GOODS_RETURN_UPDATED',
  goodsReturnCreated = 'GOODS_RETURN_CREATED',
}

export interface WebhookSearchParams extends QueryStringParams {}
