import { Component, OnInit, OnDestroy } from '@angular/core'
import { filter, fromEvent, Subject, takeUntil } from 'rxjs'
import { NgxSpinnerService } from 'ngx-spinner'

// SDK
import { KEYS_TO_SYNC, AuthStoreService } from '@evologi/shared/auth-store'
import {
  AuthNotification,
  AuthRepository,
  KeyboardShortcutsService,
} from '@evologi/shared/data-access-api'

@Component({
  selector: 'op-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public loading = false

  // Observables
  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private authStoreService: AuthStoreService,
    private authRepository: AuthRepository,
    private spinner: NgxSpinnerService,
    private keyboardShortcuts: KeyboardShortcutsService,
  ) {}

  // Lifecycle

  ngOnInit(): void {
    this.keyboardShortcuts.init()
    this.initStore()
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  // Utilities

  initStore() {
    // Loading management
    this.authStoreService
      .isLoading$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) =>
        isLoading ? this.spinner.show() : this.spinner.hide(),
      )

    // Local Storage
    fromEvent(window, 'storage')
      .pipe(
        takeUntil(this.destroy$),
        filter((event: any) => KEYS_TO_SYNC.includes(event.key)),
      )
      .subscribe((event) =>
        this.authStoreService.syncStore(event.key as string),
      )

    // Notification
    this.authStoreService
      .getNotification$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (notification) => notification && this.parseNotification(notification),
      )
  }

  // Utilities

  parseNotification(notification: AuthNotification) {
    if (notification.type === 'notification') {
      this.authRepository.notify(notification)
    }
  }
}
