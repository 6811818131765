import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { omit } from 'lodash'
import {
  Observable,
  switchMap,
  tap,
  filter,
  map,
  withLatestFrom,
  mergeMap,
} from 'rxjs'
import {
  Order,
  OrderField,
  OrderSearchParams,
  OrdersListingData,
  OrdersListingPage,
} from '../order.model'
import { isQueryStringFiltered } from '../../../libs/query-string.lib'
import { Page } from '../../../models/util.model'
import { OrdersRepository } from '../orders.repository'
import { User } from '../../users'
import { Tag } from '../../tags'
import { PickingList } from '../../picking-lists'
import { Product } from '../../products'
import { Warehouse } from '../../warehouses'
import { Currency } from '../../currencies'
import { Payment } from '../../payments'
import { Carrier } from '../../carriers'
import { Country } from '../../countries'
import { Channel } from '../../channels'
import { Reason } from '../../reasons'

export interface OrdersListingState extends OrdersListingPage {
  searchString?: string
  selected?: Order[]
  fields?: OrderField[]
  filters?: OrderSearchParams
  searchKeys?: OrderSearchParams
  customFilters?: OrderSearchParams
  trackingNumber?: string
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

export interface OrdersFilterData {
  params?: OrderSearchParams
  count?: number
}

const ORDERS_INITIAL_STATE: OrdersListingState = {
  isLoading: false,
  isInitialized: false,
  toReload: false,
}

@Injectable()
export class OrdersListingUseCase extends ComponentStore<OrdersListingState> {
  constructor(private ordersRepository: OrdersRepository) {
    super(ORDERS_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<OrdersListingState> = this.select(
    (state) => state,
  )

  readonly selectIsInitialized$: Observable<boolean> = this.select(
    (state) => state.isInitialized,
  )

  readonly selectSearchString$: Observable<string | undefined> = this.select(
    (state) => state.searchString,
  )

  readonly selectFilters$: Observable<OrderSearchParams | undefined> =
    this.select((state) => state.filters)

  readonly selectSearchFilters$: Observable<OrderSearchParams | undefined> =
    this.selectFilters$.pipe(
      mergeMap((filters) =>
        this.selectSearchString$.pipe(
          map((q) => ({ ...(q ? { q } : {}), ...(filters ? filters : {}) })),
        ),
      ),
    )

  readonly selectFilterData$: Observable<OrdersFilterData> =
    this.selectState$.pipe(map((state) => this.parseStateFilterData(state)))

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectIsFiltered$: Observable<boolean> =
    this.selectSearchFilters$.pipe(
      filter((filters) => !!filters),
      map((filters) => isQueryStringFiltered(filters || {})),
    )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectTrackingNumber$: Observable<string | undefined> = this.select(
    (state) => state.trackingNumber,
  )

  readonly selectCustomFilters$: Observable<OrderSearchParams | undefined> =
    this.select((state) => state.customFilters)

  readonly selectSearchKeys$: Observable<OrderSearchParams | undefined> =
    this.select((state) => state.searchKeys)

  readonly selectOrders$: Observable<Order[]> = this.select(
    (state) => state.data || [],
  )

  readonly selectOrdersSelected$: Observable<Order[] | undefined> = this.select(
    (state) => state.selected,
  )

  readonly selectTotalCount$: Observable<number> = this.select(
    (state) => state.totalCount || 0,
  )

  readonly selectFields$: Observable<OrderField[] | undefined> = this.select(
    (state) => state.fields,
  )

  readonly selectExtData$: Observable<OrdersListingData | undefined> =
    this.select((state) => state.extData)

  readonly selectUsers$: Observable<User[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.users))

  readonly selectTags$: Observable<Tag[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.tags))

  readonly selectPickings$: Observable<PickingList[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.pickingLists))

  readonly selectProducts$: Observable<Product[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.products))

  readonly selectWarehouses$: Observable<Warehouse[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.warehouses))

  readonly selectCurrencies$: Observable<Currency[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.currencies))

  readonly selectPayments$: Observable<Payment[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.payments))

  readonly selectCarriers$: Observable<Carrier[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.carriers))

  readonly selectChannels$: Observable<Channel[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.channels))

  readonly selectCountries$: Observable<Country[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.countries))

  readonly selectReasons$: Observable<Reason[] | undefined> =
    this.selectExtData$.pipe(map((extData) => extData?.reasons))

  readonly selectPage$: Observable<Page<Order>> = this.selectIsLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() =>
      this.select(
        this.selectOrders$,
        this.selectTotalCount$,
        (data, totalCount) => ({ totalCount, data }),
        { debounce: true },
      ),
    ),
  )

  /**
   * EFFECTS
   */

  readonly search$ = this.effect(
    (searchParams$: Observable<OrderSearchParams>) => {
      return searchParams$.pipe(
        tap((searchParams) => this.setFilters(searchParams)),
        withLatestFrom(this.selectState$),
        filter(([_, state]) => !!state.fields),
        switchMap(([_, state]) =>
          this.ordersRepository
            .searchOrders$(
              this.parseStateFilterParams(state),
              state.fields || [],
              state.extData,
            )
            .pipe(
              tapResponse(
                (pageData) => pageData && this.setPage(pageData),
                (error: HttpErrorResponse) => this.setError(error),
              ),
            ),
        ),
      )
    },
  )

  readonly searchAll$ = this.effect(
    (searchParams$: Observable<OrderSearchParams>) => {
      return searchParams$.pipe(
        withLatestFrom(this.selectState$),
        filter(([params, state]) => !!state.fields),
        map(([params, state]) => ({
          params: {
            ...params,
            ...(state.searchString ? { q: state.searchString } : {}),
            limit: state.totalCount,
          },
          fields: state.fields || [],
          extData: state.extData,
        })),
        tap((searchParams) => this.setFilters(searchParams.params)),
        switchMap(({ params, fields, extData }) =>
          this.ordersRepository.searchOrders$(params, fields, extData).pipe(
            tapResponse(
              (pageData) => this.setPage(pageData),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly loadOrderProducts$ = this.effect((orderId$: Observable<string>) => {
    return orderId$.pipe(
      withLatestFrom(this.selectProducts$),
      tap(() => this.setIsLoading(true)),
      switchMap(([orderId, products]) =>
        this.ordersRepository.getOrderProducts$(orderId, products).pipe(
          tapResponse(
            (products) => this.setProducts(products),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly searchPickingList$ = this.effect(
    (goodsReceiveId$: Observable<string>) => {
      return goodsReceiveId$.pipe(
        switchMap((goodsReceiveId) =>
          this.ordersRepository.getPickingListIds$(goodsReceiveId).pipe(
            tapResponse(
              (orderIds) => this.setSearchKeys({ _id: orderIds }),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  /**
   * REDUCERS
   */

  readonly setOptions = this.updater(
    (state, newState: Partial<OrdersListingState>): OrdersListingState => {
      return { ...state, ...newState }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): OrdersListingState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setSearchString = this.updater(
    (state, searchString: string): OrdersListingState => {
      return {
        ...state,
        searchString,
        toReload: true,
      }
    },
  )

  readonly setOrdersSelected = this.updater(
    (state, orders: Order[] | undefined): OrdersListingState => {
      return {
        ...state,
        selected: orders,
      }
    },
  )

  readonly setPage = this.updater(
    (state, page: OrdersListingPage): OrdersListingState => {
      return {
        ...state,
        data: page.data,
        extData: page.extData,
        totalCount: page.totalCount,
        toReload: false,
        isLoading: false,
        isInitialized: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): OrdersListingState => {
      return {
        ...state,
        data: undefined,
        totalCount: 0,
        error,
        isInitialized: true,
        isLoading: false,
        toReload: false,
      }
    },
  )

  readonly setReload = this.updater(
    (state, toReload: boolean): OrdersListingState => {
      return {
        ...state,
        toReload,
      }
    },
  )

  readonly setFields = this.updater(
    (state, fields: OrderField[]): OrdersListingState => {
      return {
        ...state,
        fields,
        toReload: state.isInitialized,
      }
    },
  )

  readonly setFilters = this.updater(
    (state, filters: OrderSearchParams = {}): OrdersListingState => {
      return {
        ...state,
        filters,
        isLoading: true,
        isInitialized: state.isInitialized,
      }
    },
  )

  readonly setCustomFilters = this.updater(
    (state, customFilters?: OrderSearchParams): OrdersListingState => {
      return {
        ...state,
        customFilters,
        toReload: state.isInitialized,
      }
    },
  )

  readonly setSearchKeys = this.updater(
    (state, searchKeys?: OrderSearchParams): OrdersListingState => {
      return {
        ...state,
        searchKeys,
        toReload: state.isInitialized,
      }
    },
  )

  readonly resetState = this.updater(
    (state): OrdersListingState => ORDERS_INITIAL_STATE,
  )

  readonly resetError = this.updater((state): OrdersListingState => {
    return {
      ...state,
      error: undefined,
    }
  })

  readonly resetFilters = this.updater((state): OrdersListingState => {
    return {
      ...state,
      filters: undefined,
      searchString: undefined,
      trackingNumber: undefined,
      error: undefined,
      toReload: true,
    }
  })

  readonly setTrackingNumber = this.updater(
    (state, trackingNumber: string | undefined): OrdersListingState => {
      return {
        ...state,
        trackingNumber,
        toReload: true,
      }
    },
  )

  readonly setProducts = this.updater(
    (state, products: Product[]): OrdersListingState => {
      return {
        ...state,
        extData: {
          ...(state.extData || {}),
          products,
        },
        isLoading: false,
      }
    },
  )

  /**
   * Utilities
   */

  private parseStateFilterParams(state: OrdersListingState): OrderSearchParams {
    let params: OrderSearchParams = state.filters || {}
    if (state.searchString) {
      params.q = state.searchString
    }
    if (state.trackingNumber) {
      params.trackingNumber = state.trackingNumber
    }
    if (state.customFilters) {
      params = { ...params, ...state.customFilters }
    }
    if (state.searchKeys) {
      params = { ...params, ...state.searchKeys }
    }

    return params
  }

  private parseStateFilterData(state: OrdersListingState): OrdersFilterData {
    if (state.selected?.length) {
      return {
        params: {
          _id: state.selected.map((o) => o._id),
        },
        count: state.selected.length,
      }
    }

    return {
      params: omit(this.parseStateFilterParams(state), [
        'limit',
        'offset',
        'sort',
        'order',
      ]),
      count: state.totalCount,
    }
  }
}
