import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { EMPTY, Observable, map, switchMap, tap, withLatestFrom } from 'rxjs'
import moment from 'moment'

import { OrderDashboard } from '../order.model'
import { OrdersService } from '../orders.service'

interface OrdersDashboardState extends OrderDashboard {
  startDate?: string
  endDate?: string
  isLoading: boolean
  error?: HttpErrorResponse
}

interface DashboardSearchPeriod {
  startDate?: string | Date
  endDate?: string | Date
}

interface DashboardPeriod {
  startDate: string
  endDate: string
}

const DASHBOARD_INITIAL_STATE: OrdersDashboardState = {
  ordersCount: 0,
  itemsSold: 0,
  averageItemsPerOrder: 0,
  averageTotalOrderAmount: 0,
  averageRowsPerOrder: 0,
  ordersPerChannels: {},
  ordersPerStatus: {},
  isLoading: false,
}

@Injectable()
export class OrdersDashboardUseCase extends ComponentStore<OrdersDashboardState> {
  constructor(private ordersService: OrdersService) {
    super(DASHBOARD_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectPeriod$ = this.select((state) => ({
    startDate: state.startDate,
    endDate: state.endDate,
  }))

  readonly selectIsLoading$ = this.select((state) => state.isLoading)

  readonly selectStartDate$ = this.select((state) => state.startDate)

  readonly selectEndDate$ = this.select((state) => state.endDate)

  readonly selectOrdersPerChannels$ = this.select(
    (state) => state.ordersPerChannels,
  )

  readonly selectOrdersPerStatus$ = this.select(
    (state) => state.ordersPerStatus,
  )

  readonly selectDashboard$ = this.select((state) => ({
    ordersCount: state.ordersCount,
    itemsSold: state.itemsSold,
    averageItemsPerOrder: state.averageItemsPerOrder,
    averageRowsPerOrder: state.averageRowsPerOrder,
    averageTotalOrderAmount: state.averageTotalOrderAmount,
    ordersPerChannels: state.ordersPerChannels,
    ordersPerStatus: state.ordersPerStatus,
  }))

  /**
   * EFFECTS
   */

  readonly search$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectPeriod$),
      switchMap(([_, period]) => {
        if (!period.startDate || !period.endDate) {
          return EMPTY
        }

        this.setIsLoading(true)
        return this.ordersService
          .dashboard$(period.startDate, period.endDate)
          .pipe(
            tapResponse(
              (dashboard) => this.setDashboard(dashboard),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          )
      }),
    )
  })

  readonly searchByPeriod$ = this.effect(
    (period$: Observable<DashboardSearchPeriod>) => {
      return period$.pipe(
        map((period) => this.parseSearchPeriod(period)),
        tap((period) => this.setPeriod(period)),
        switchMap((period) =>
          this.ordersService.dashboard$(period.startDate, period.endDate).pipe(
            tapResponse(
              (dashboard) => this.setDashboard(dashboard),
              (error: HttpErrorResponse) => this.setError(error),
            ),
          ),
        ),
      )
    },
  )

  readonly searchByDays$ = this.effect((days$: Observable<number>) => {
    return days$.pipe(
      map((days) => this.parseSearchPeriod({}, days)),
      tap((period) => this.setPeriod(period)),
      switchMap((period) =>
        this.ordersService.dashboard$(period.startDate, period.endDate).pipe(
          tapResponse(
            (dashboard) => this.setDashboard(dashboard),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  /**
   * REDUCERS
   */

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): OrdersDashboardState => ({
      ...state,
      isLoading,
      error: undefined,
    }),
  )

  readonly setStartDate = this.updater(
    (state, startDate: string): OrdersDashboardState => {
      return {
        ...state,
        startDate,
      }
    },
  )

  readonly setEndDate = this.updater(
    (state, endDate: string): OrdersDashboardState => {
      return {
        ...state,
        endDate,
      }
    },
  )

  readonly setPeriod = this.updater(
    (state, period: DashboardPeriod): OrdersDashboardState => {
      return {
        ...state,
        ...period,
        isLoading: true,
        error: undefined,
      }
    },
  )

  readonly setDashboard = this.updater(
    (state, dashboard: OrderDashboard): OrdersDashboardState => {
      return {
        ...state,
        ...dashboard,
        isLoading: false,
        error: undefined,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): OrdersDashboardState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  // Utilities

  private parseSearchPeriod(
    period: DashboardSearchPeriod,
    days = 7,
  ): DashboardPeriod {
    return {
      startDate: moment(period.startDate)
        .subtract(days, 'days')
        .startOf('day')
        .toISOString(),
      endDate: moment(period.endDate).endOf('day').toISOString(),
    }
  }
}
