import { Pipe, PipeTransform } from '@angular/core'

import { PickingList } from '../picking-list.model'
import {
  getPickingMissionCurrentIndex,
  getPickingMissionsCompleted,
} from '../libs/missions.lib'
import {
  getPickingOrderPickedQty,
  getPickingOrderQtyToPick,
  getPickingOrdersCompleted,
  getPickingOrderStatus,
} from '../libs/orders.lib'
import { getPickingLocationsPicked } from '../libs/picking-list.lib'

@Pipe({
  name: 'pickingList',
})
export class PickingListPipe implements PipeTransform {
  transform(
    pickingList: PickingList | undefined,
    kind:
      | 'orders-completed'
      | 'order-status'
      | 'order-to-pick'
      | 'order-picked'
      | 'missions-count'
      | 'missions-completed'
      | 'mission-current-index'
      | 'locations-picked',
    params?: {
      orderId?: string
      productId?: string
    },
  ) {
    if (!pickingList) {
      return ''
    }

    switch (kind) {
      case 'orders-completed':
        return getPickingOrdersCompleted(pickingList)
      case 'order-status':
        return params?.orderId
          ? getPickingOrderStatus(pickingList, params.orderId)
          : ''
      case 'order-to-pick':
        return params?.orderId
          ? getPickingOrderQtyToPick(pickingList, params.orderId)
          : ''
      case 'order-picked':
        return params?.orderId
          ? getPickingOrderPickedQty(pickingList, params.orderId)
          : ''
      case 'missions-count':
        return pickingList.missions.length
      case 'missions-completed':
        return getPickingMissionsCompleted(pickingList)
      case 'mission-current-index':
        return getPickingMissionCurrentIndex(pickingList) || ''
      case 'locations-picked':
        return params?.productId
          ? getPickingLocationsPicked(pickingList, params.productId)
          : ''
    }
  }
}
