import {
  PermissionLabel,
  PermissionLevelLabel,
  PermissionObjectLabel,
  PermissionsGroup,
  PermissionsPreset,
} from './policy.model'

export const PERMISSIONS: PermissionLabel[] = [
  { scope: 'activities', label: 'Import/Export' },
  { scope: 'banners', label: 'Insegne' },
  { scope: 'bordereaus', label: 'Distinte vettori' },
  { scope: 'brands', label: 'Brand' },
  { scope: 'carriers', label: 'Corrieri' },
  { scope: 'catalogs', label: 'Cataloghi' },
  { scope: 'channels', label: 'Canali di vendita' },
  { scope: 'classifications', label: 'Classificazioni' },
  { scope: 'customers', label: 'Clienti' },
  { scope: 'files', label: 'Media' },
  { scope: 'goodsReceives', label: 'Arrivi merce' },
  { scope: 'goodsReturns', label: 'Gestione resi' },
  { scope: 'iam', label: 'Gesione utenti' },
  { scope: 'integrations', label: 'Integrazioni' },
  { scope: 'invoices', label: 'Fatture' },
  { scope: 'manufacturers', label: 'Produttori' },
  { scope: 'movements', label: 'Movimenti' },
  {
    scope: 'orders',
    label: 'Ordini',
    objects: ['WAREHOUSE_ORDERS', 'PICKUP_POINT_ORDERS'],
  },
  { scope: 'packingLists', label: 'Packing' },
  { scope: 'payments', label: 'Pagamenti' },
  { scope: 'locations', label: 'Magazzini' },
  { scope: 'pickingLists', label: 'Picking List' },
  { scope: 'pickupPoints', label: 'Pickup Point' },
  {
    scope: 'products',
    label: 'Prodotti',
    objects: ['SUPPLIER_PRODUCTS'],
  },
  {
    scope: 'replenishments',
    label: 'Missioni di trasferimento',
  },
  { scope: 'supplierOrders', label: 'Ordini fornitore' },
  { scope: 'suppliers', label: 'Fornitori' },
  {
    scope: 'tenant',
    label: 'Impostazioni generali',
    levels: ['READ_ONLY', 'READ_WRITE'],
  },
  { scope: 'categories', label: 'Categorie' },
]

export const PERMISSIONS_LEVELS: PermissionLevelLabel[] = [
  {
    level: 'DENY',
    label: 'Non consentito',
  },
  {
    level: 'READ_ONLY',
    label: 'Sola lettura',
  },
  {
    level: 'WRITE_ONLY',
    label: 'Sola scrittura',
  },
  {
    level: 'READ_WRITE',
    label: 'Lettura/Scrittura',
  },
]

export const PERMISSIONS_PRODUCTS_LEVELS: PermissionObjectLabel[] = [
  {
    object: 'SUPPLIER_PRODUCTS',
    label: 'Fornitore',
  },
]

export const PERMISSIONS_ORDERS_LEVELS: PermissionObjectLabel[] = [
  {
    object: 'WAREHOUSE_ORDERS',
    label: 'Magazzino',
  },
  {
    object: 'PICKUP_POINT_ORDERS',
    label: 'Pickup point',
  },
]

export const PERMISSIONS_GROUPS: PermissionsGroup[] = [
  {
    code: 'catalog',
    label: 'Catalogo',
    permissions: [
      'products',
      'categories',
      'classifications',
      'brands',
      'catalogs',
      'manufacturers',
    ],
  },
  {
    code: 'registries',
    label: 'Anagrafiche',
    permissions: ['customers', 'suppliers'],
  },
  {
    code: 'sales',
    label: 'Vendite',
    permissions: ['orders', 'invoices', 'goodsReturns'],
  },
  {
    code: 'purchases',
    label: 'Acquisti',
    permissions: ['supplierOrders', 'goodsReceives'],
  },
  {
    code: 'logistic',
    label: 'Logistica',
    permissions: [
      'pickingLists',
      'packingLists',
      'bordereaus',
      'movements',
      'replenishments',
    ],
  },
  {
    code: 'io',
    label: 'Import/Export',
    permissions: ['activities'],
  },
  {
    code: 'media',
    label: 'Media',
    permissions: ['files'],
  },
  {
    code: 'structures',
    label: 'Strutture',
    permissions: ['locations', 'banners', 'pickupPoints'],
  },
  {
    code: 'sitemap',
    label: 'E-commerce',
    permissions: ['channels', 'payments'],
  },
  {
    code: 'settings',
    label: 'Impostazioni',
    permissions: ['tenant', 'carriers', 'iam', 'integrations'],
  },
]

export const PERMISSIONS_PRESETS: PermissionsPreset[] = [
  {
    code: 'admin',
    label: 'Amministratore',
    permissions: [
      {
        scope: 'products',
        level: 'READ_WRITE',
      },
      {
        scope: 'categories',
        level: 'READ_WRITE',
      },
      {
        scope: 'classifications',
        level: 'READ_WRITE',
      },
      {
        scope: 'brands',
        level: 'READ_WRITE',
      },
      {
        scope: 'catalogs',
        level: 'READ_WRITE',
      },
      {
        scope: 'manufacturers',
        level: 'READ_WRITE',
      },
      {
        scope: 'orders',
        level: 'READ_WRITE',
      },
      {
        scope: 'pickingLists',
        level: 'READ_WRITE',
      },
      {
        scope: 'packingLists',
        level: 'READ_WRITE',
      },
      {
        scope: 'bordereaus',
        level: 'READ_WRITE',
      },
      {
        scope: 'customers',
        level: 'READ_WRITE',
      },
      {
        scope: 'suppliers',
        level: 'READ_WRITE',
      },
      {
        scope: 'channels',
        level: 'READ_WRITE',
      },
      {
        scope: 'payments',
        level: 'READ_WRITE',
      },
      {
        scope: 'carriers',
        level: 'READ_WRITE',
      },
      {
        scope: 'locations',
        level: 'READ_WRITE',
      },
      {
        scope: 'iam',
        level: 'READ_WRITE',
      },
      {
        scope: 'tenant',
        level: 'READ_WRITE',
      },
      {
        scope: 'pickupPoints',
        level: 'READ_WRITE',
      },
      {
        scope: 'banners',
        level: 'READ_WRITE',
      },
      {
        scope: 'files',
        level: 'READ_WRITE',
      },
      {
        scope: 'integrations',
        level: 'READ_WRITE',
      },
      {
        scope: 'invoices',
        level: 'READ_WRITE',
      },
      {
        scope: 'goodsReturns',
        level: 'READ_WRITE',
      },
      {
        scope: 'supplierOrders',
        level: 'READ_WRITE',
      },
      {
        scope: 'goodsReceives',
        level: 'READ_WRITE',
      },
      {
        scope: 'movements',
        level: 'READ_WRITE',
      },
      {
        scope: 'activities',
        level: 'READ_WRITE',
      },
    ],
  },
  {
    code: 'pick-pack',
    label: 'Pick&Pack',
    permissions: [
      {
        scope: 'products',
        level: 'READ_WRITE',
      },
      {
        scope: 'categories',
        level: 'READ_ONLY',
      },
      {
        scope: 'classifications',
        level: 'READ_ONLY',
      },
      {
        scope: 'brands',
        level: 'READ_ONLY',
      },
      {
        scope: 'catalogs',
        level: 'READ_ONLY',
      },
      {
        scope: 'manufacturers',
        level: 'READ_ONLY',
      },
      {
        scope: 'orders',
        level: 'READ_ONLY',
      },
      {
        scope: 'pickingLists',
        level: 'READ_WRITE',
      },
      {
        scope: 'packingLists',
        level: 'READ_WRITE',
      },
      {
        scope: 'bordereaus',
        level: 'READ_WRITE',
      },
      {
        scope: 'customers',
        level: 'READ_ONLY',
      },
      {
        scope: 'suppliers',
        level: 'READ_ONLY',
      },
      {
        scope: 'channels',
        level: 'READ_ONLY',
      },
      {
        scope: 'payments',
        level: 'READ_ONLY',
      },
      {
        scope: 'carriers',
        level: 'READ_ONLY',
      },
      {
        scope: 'locations',
        level: 'READ_ONLY',
      },
      {
        scope: 'iam',
        level: 'READ_ONLY',
      },
      {
        scope: 'tenant',
        level: 'READ_ONLY',
      },
      {
        scope: 'pickupPoints',
        level: 'READ_ONLY',
      },
    ],
  },
  {
    code: 'warehouse',
    label: 'Carico merce',
    permissions: [
      {
        scope: 'products',
        level: 'READ_WRITE',
      },
      {
        scope: 'movements',
        level: 'READ_WRITE',
      },
      {
        scope: 'categories',
        level: 'READ_ONLY',
      },
      {
        scope: 'classifications',
        level: 'READ_ONLY',
      },
      {
        scope: 'brands',
        level: 'READ_ONLY',
      },
      {
        scope: 'catalogs',
        level: 'READ_ONLY',
      },
      {
        scope: 'manufacturers',
        level: 'READ_ONLY',
      },
      {
        scope: 'suppliers',
        level: 'READ_WRITE',
      },
      {
        scope: 'supplierOrders',
        level: 'READ_WRITE',
      },
      {
        scope: 'goodsReceives',
        level: 'READ_WRITE',
      },
      {
        scope: 'payments',
        level: 'READ_WRITE',
      },
      {
        scope: 'locations',
        level: 'READ_WRITE',
      },
      {
        scope: 'iam',
        level: 'READ_ONLY',
      },
      {
        scope: 'tenant',
        level: 'READ_ONLY',
      },
      {
        scope: 'pickupPoints',
        level: 'READ_WRITE',
      },
    ],
  },
  {
    code: 'catalog',
    label: 'Gestione catalogo',
    permissions: [
      {
        scope: 'products',
        level: 'READ_WRITE',
      },
      {
        scope: 'categories',
        level: 'READ_WRITE',
      },
      {
        scope: 'classifications',
        level: 'READ_WRITE',
      },
      {
        scope: 'brands',
        level: 'READ_WRITE',
      },
      {
        scope: 'catalogs',
        level: 'READ_WRITE',
      },
      {
        scope: 'manufacturers',
        level: 'READ_WRITE',
      },
      {
        scope: 'locations',
        level: 'READ_ONLY',
      },
      {
        scope: 'locations',
        level: 'READ_ONLY',
      },
      {
        scope: 'iam',
        level: 'READ_ONLY',
      },
      {
        scope: 'tenant',
        level: 'READ_ONLY',
      },
      {
        scope: 'pickupPoints',
        level: 'READ_ONLY',
      },
    ],
  },
  {
    code: 'purchases',
    label: 'Gestione acquisti',
    permissions: [
      {
        scope: 'products',
        level: 'READ_ONLY',
      },
      {
        scope: 'categories',
        level: 'READ_ONLY',
      },
      {
        scope: 'classifications',
        level: 'READ_ONLY',
      },
      {
        scope: 'brands',
        level: 'READ_ONLY',
      },
      {
        scope: 'catalogs',
        level: 'READ_ONLY',
      },
      {
        scope: 'manufacturers',
        level: 'READ_ONLY',
      },
      {
        scope: 'suppliers',
        level: 'READ_WRITE',
      },
      {
        scope: 'supplierOrders',
        level: 'READ_WRITE',
      },
      {
        scope: 'goodsReceives',
        level: 'READ_WRITE',
      },
      {
        scope: 'orders',
        level: 'READ_ONLY',
      },
      {
        scope: 'customers',
        level: 'READ_ONLY',
      },
      {
        scope: 'pickingLists',
        level: 'READ_ONLY',
      },
      {
        scope: 'packingLists',
        level: 'READ_ONLY',
      },
      {
        scope: 'bordereaus',
        level: 'READ_ONLY',
      },
      {
        scope: 'locations',
        level: 'READ_ONLY',
      },
      {
        scope: 'payments',
        level: 'READ_ONLY',
      },
      {
        scope: 'channels',
        level: 'READ_ONLY',
      },
      {
        scope: 'carriers',
        level: 'READ_ONLY',
      },
      {
        scope: 'iam',
        level: 'READ_ONLY',
      },
      {
        scope: 'tenant',
        level: 'READ_ONLY',
      },
      {
        scope: 'pickupPoints',
        level: 'READ_ONLY',
      },
    ],
  },
  {
    code: 'sales',
    label: 'Gestione vendite',
    permissions: [
      {
        scope: 'products',
        level: 'READ_ONLY',
      },
      {
        scope: 'categories',
        level: 'READ_ONLY',
      },
      {
        scope: 'classifications',
        level: 'READ_ONLY',
      },
      {
        scope: 'brands',
        level: 'READ_ONLY',
      },
      {
        scope: 'catalogs',
        level: 'READ_ONLY',
      },
      {
        scope: 'manufacturers',
        level: 'READ_ONLY',
      },
      {
        scope: 'suppliers',
        level: 'READ_WRITE',
      },
      {
        scope: 'orders',
        level: 'READ_WRITE',
      },
      {
        scope: 'invoices',
        level: 'READ_WRITE',
      },
      {
        scope: 'goodsReturns',
        level: 'READ_WRITE',
      },
      {
        scope: 'pickingLists',
        level: 'READ_ONLY',
      },
      {
        scope: 'packingLists',
        level: 'READ_ONLY',
      },
      {
        scope: 'bordereaus',
        level: 'READ_ONLY',
      },
      {
        scope: 'payments',
        level: 'READ_WRITE',
      },
      {
        scope: 'locations',
        level: 'READ_ONLY',
      },
      {
        scope: 'carriers',
        level: 'READ_ONLY',
      },
      {
        scope: 'channels',
        level: 'READ_WRITE',
      },
      {
        scope: 'iam',
        level: 'READ_ONLY',
      },
      {
        scope: 'tenant',
        level: 'READ_ONLY',
      },
      {
        scope: 'pickupPoints',
        level: 'READ_ONLY',
      },
    ],
  },
  {
    code: 'basic',
    label: 'Base',
    permissions: [
      {
        scope: 'products',
        level: 'READ_ONLY',
      },
      {
        scope: 'categories',
        level: 'READ_ONLY',
      },
      {
        scope: 'classifications',
        level: 'READ_ONLY',
      },
      {
        scope: 'brands',
        level: 'READ_ONLY',
      },
      {
        scope: 'catalogs',
        level: 'READ_ONLY',
      },
      {
        scope: 'manufacturers',
        level: 'READ_ONLY',
      },
      {
        scope: 'customers',
        level: 'READ_ONLY',
      },
      {
        scope: 'suppliers',
        level: 'DENY',
      },
      {
        scope: 'orders',
        level: 'READ_ONLY',
      },
      {
        scope: 'invoices',
        level: 'DENY',
      },
      {
        scope: 'goodsReturns',
        level: 'READ_WRITE',
      },
      {
        scope: 'supplierOrders',
        level: 'DENY',
      },
      {
        scope: 'goodsReceives',
        level: 'DENY',
      },
      {
        scope: 'pickingLists',
        level: 'READ_ONLY',
      },
      {
        scope: 'packingLists',
        level: 'DENY',
      },
      {
        scope: 'bordereaus',
        level: 'READ_ONLY',
      },
      {
        scope: 'movements',
        level: 'DENY',
      },
      {
        scope: 'replenishments',
        level: 'DENY',
      },
      { scope: 'activities', level: 'DENY' },
      { scope: 'files', level: 'READ_ONLY' },
      {
        scope: 'locations',
        level: 'READ_ONLY',
      },
      {
        scope: 'banners',
        level: 'DENY',
      },
      {
        scope: 'pickupPoints',
        level: 'DENY',
      },
      {
        scope: 'channels',
        level: 'READ_ONLY',
      },
      {
        scope: 'payments',
        level: 'READ_ONLY',
      },
      {
        scope: 'tenant',
        level: 'READ_ONLY',
      },
      {
        scope: 'carriers',
        level: 'READ_ONLY',
      },
      {
        scope: 'iam',
        level: 'DENY',
      },
      { scope: 'integrations', level: 'DENY' },
    ],
  },
  {
    code: 'plus',
    label: 'Plus',
    permissions: [
      {
        scope: 'products',
        level: 'READ_WRITE',
      },
      {
        scope: 'categories',
        level: 'READ_WRITE',
      },
      {
        scope: 'classifications',
        level: 'READ_WRITE',
      },
      {
        scope: 'brands',
        level: 'READ_WRITE',
      },
      {
        scope: 'catalogs',
        level: 'READ_WRITE',
      },
      {
        scope: 'manufacturers',
        level: 'READ_WRITE',
      },
      {
        scope: 'customers',
        level: 'READ_WRITE',
      },
      {
        scope: 'suppliers',
        level: 'READ_WRITE',
      },
      {
        scope: 'orders',
        level: 'READ_WRITE',
      },
      {
        scope: 'invoices',
        level: 'READ_WRITE',
      },
      {
        scope: 'goodsReturns',
        level: 'READ_WRITE',
      },
      {
        scope: 'supplierOrders',
        level: 'DENY',
      },
      {
        scope: 'goodsReceives',
        level: 'DENY',
      },
      {
        scope: 'pickingLists',
        level: 'READ_ONLY',
      },
      {
        scope: 'packingLists',
        level: 'DENY',
      },
      {
        scope: 'bordereaus',
        level: 'READ_ONLY',
      },
      {
        scope: 'movements',
        level: 'DENY',
      },
      {
        scope: 'replenishments',
        level: 'DENY',
      },
      { scope: 'activities', level: 'DENY' },
      { scope: 'files', level: 'READ_WRITE' },
      {
        scope: 'locations',
        level: 'READ_ONLY',
      },
      {
        scope: 'banners',
        level: 'DENY',
      },
      {
        scope: 'pickupPoints',
        level: 'DENY',
      },
      {
        scope: 'channels',
        level: 'READ_ONLY',
      },
      {
        scope: 'payments',
        level: 'READ_ONLY',
      },
      {
        scope: 'tenant',
        level: 'READ_ONLY',
      },
      {
        scope: 'carriers',
        level: 'READ_ONLY',
      },
      {
        scope: 'iam',
        level: 'DENY',
      },
      { scope: 'integrations', level: 'DENY' },
    ],
  },
  {
    code: 'premium',
    label: 'Premium',
    permissions: [
      {
        scope: 'products',
        level: 'READ_WRITE',
      },
      {
        scope: 'categories',
        level: 'READ_WRITE',
      },
      {
        scope: 'classifications',
        level: 'READ_WRITE',
      },
      {
        scope: 'brands',
        level: 'READ_WRITE',
      },
      {
        scope: 'catalogs',
        level: 'READ_WRITE',
      },
      {
        scope: 'manufacturers',
        level: 'READ_WRITE',
      },
      {
        scope: 'customers',
        level: 'READ_WRITE',
      },
      {
        scope: 'suppliers',
        level: 'READ_WRITE',
      },
      {
        scope: 'orders',
        level: 'READ_WRITE',
      },
      {
        scope: 'invoices',
        level: 'READ_WRITE',
      },
      {
        scope: 'goodsReturns',
        level: 'READ_WRITE',
      },
      {
        scope: 'supplierOrders',
        level: 'READ_WRITE',
      },
      {
        scope: 'goodsReceives',
        level: 'READ_WRITE',
      },
      {
        scope: 'pickingLists',
        level: 'READ_ONLY',
      },
      {
        scope: 'packingLists',
        level: 'DENY',
      },
      {
        scope: 'bordereaus',
        level: 'READ_ONLY',
      },
      {
        scope: 'movements',
        level: 'DENY',
      },
      {
        scope: 'replenishments',
        level: 'DENY',
      },
      { scope: 'activities', level: 'DENY' },
      { scope: 'files', level: 'READ_WRITE' },
      {
        scope: 'locations',
        level: 'READ_ONLY',
      },
      {
        scope: 'banners',
        level: 'READ_ONLY',
      },
      {
        scope: 'pickupPoints',
        level: 'READ_ONLY',
      },
      {
        scope: 'channels',
        level: 'READ_ONLY',
      },
      {
        scope: 'payments',
        level: 'READ_ONLY',
      },
      {
        scope: 'tenant',
        level: 'READ_ONLY',
      },
      {
        scope: 'carriers',
        level: 'READ_ONLY',
      },
      {
        scope: 'iam',
        level: 'DENY',
      },
      { scope: 'integrations', level: 'DENY' },
    ],
  },
]
