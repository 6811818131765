import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core'
import { CommonModule, CurrencyPipe } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { PushPipe } from '@ngrx/component'
import * as Sentry from '@sentry/angular-ivy'

// Interceptors
import { ModalInterceptor, ServerErrorInterceptor } from './interceptors/'

// Directives

// Services
import { ModalService } from './services'

// Modules
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { ButtonsModule } from 'ngx-bootstrap/buttons'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { ModalModule } from 'ngx-bootstrap/modal'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

import { FileUploadModule } from 'ng2-file-upload'
import { ArchwizardModule } from '@rg-software/angular-archwizard'
import { ColorPickerModule } from 'ngx-color-picker'
import { LoadingBarModule } from '@ngx-loading-bar/core'
import { LoadingBarRouterModule } from '@ngx-loading-bar/router'

// SDK Library
import { SharedUtilDevextremeModule } from '@evologi/shared/util-devextreme'
import {
  AUTH_MANAGER,
  AuthDirective,
  AuthGuard,
  AuthInterceptor,
  AuthPipe,
  CACHE_MANAGER,
  CarrierAddressWarningDirective,
  CatalogLocalePipe,
  LocalesPipe,
  MODAL_MANAGER,
  NOTIFICATION_MANAGER,
  PermissionGuard,
  RefreshInterceptor,
  ReviveInterceptor,
  SharedDataAccessApiModule,
  UnAuthGuard,
} from '@evologi/shared/data-access-api'
import { SharedUtilTranslationModule } from '@evologi/shared/util-translation'
import { SharedUtilToolkitModule } from '@evologi/shared/util-toolkit'
import {
  AdminUiModule,
  ModalService as ModalManager,
  NotificationService,
  ErrorHandlerService,
} from '@evologi/admin/ui'
import { CacheService, AuthStoreService } from '@evologi/shared/auth-store'

// Components
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component'
import { CommentsComponent } from './components/comments/comments.component'
import { FooterInfoComponent } from './components/footer-info/footer-info.component'
import { HelperComponent } from './components/helper/helper.component'
import { MetaComponent } from './components/meta/meta.component'
import { MetaInputComponent } from './components/meta-input/meta-input.component'
import { MetaItemComponent } from './components/meta-item/meta-item.component'
import { ModalAlertComponent } from './components/modal-alert/modal-alert.component'
import { ModalClosureComponent } from './components/modal-closure/modal-closure.component'
import { ModalDeleteComponent } from './components/modal-delete/modal-delete.component'
import { ModalDeleteDeprecatedComponent } from './components/modal-delete-deprecated/modal-delete.component'
import { ModalFrameComponent } from './components/modal-frame/modal-frame.component'
import { ModalHistoryComponent } from './components/modal-history/modal-history.component'
import { PageFrameComponent, PageStatusComponent } from './components/page'
import { RouteStatusComponent, RouteStepComponent } from './components/route'
import { SearchComponent } from './components/search/search.component'
import { SearchSelectComponent } from './components/search-select/search-select.component'
import { ToolsComponent } from './components/tools/tools.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,

    // Uploader
    FileUploadModule,

    // ColorPicker
    ColorPickerModule,

    // ArchWizard
    ArchwizardModule,

    // Loading bar
    LoadingBarModule,
    LoadingBarRouterModule,

    // Ngx-boostrap
    AccordionModule.forRoot(),
    BsDropdownModule,
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forChild(),
    PopoverModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),

    // Internal modules
    AdminUiModule,
    SharedUtilTranslationModule.forRoot(),
    SharedUtilToolkitModule,
    SharedUtilDevextremeModule,
    SharedDataAccessApiModule,

    // Store components
    PushPipe,
  ],
  declarations: [
    BreadcrumbsComponent,
    CommentsComponent,
    FooterInfoComponent,
    HelperComponent,
    MetaComponent,
    MetaItemComponent,
    MetaInputComponent,
    ModalAlertComponent,
    ModalClosureComponent,
    ModalDeleteComponent,
    ModalDeleteDeprecatedComponent,
    ModalFrameComponent,
    ModalHistoryComponent,
    PageFrameComponent,
    PageStatusComponent,
    RouteStatusComponent,
    RouteStepComponent,
    SearchComponent,
    SearchSelectComponent,
    ToolsComponent,

    // Directives
    AuthDirective,
    CarrierAddressWarningDirective,

    // Pipes
    AuthPipe,
    LocalesPipe,
    CatalogLocalePipe,
  ],
  exports: [
    // Components
    BreadcrumbsComponent,
    CommentsComponent,
    FooterInfoComponent,
    HelperComponent,
    MetaComponent,
    ModalAlertComponent,
    ModalClosureComponent,
    ModalDeleteComponent,
    ModalDeleteDeprecatedComponent,
    ModalFrameComponent,
    PageFrameComponent,
    PageStatusComponent,
    RouteStatusComponent,
    RouteStepComponent,
    SearchComponent,
    SearchSelectComponent,
    ToolsComponent,

    // Uploader
    FileUploadModule,

    // ColorPicker
    ColorPickerModule,

    // ArchWizard
    ArchwizardModule,

    // Loading bar
    LoadingBarModule,
    LoadingBarRouterModule,

    // Ngx-boostrap
    AccordionModule,
    BsDropdownModule,
    ButtonsModule,
    CollapseModule,
    ModalModule,
    PopoverModule,
    TabsModule,
    TooltipModule,

    // Pipes
    AuthPipe,
    PushPipe,
    LocalesPipe,
    CatalogLocalePipe,

    AdminUiModule,
    SharedUtilTranslationModule,
    SharedUtilToolkitModule,
    SharedUtilDevextremeModule,
    SharedDataAccessApiModule,

    // Directives
    AuthDirective,
    CarrierAddressWarningDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminCoreModule {
  static forRoot(): ModuleWithProviders<AdminCoreModule> {
    return {
      ngModule: AdminCoreModule,
      providers: [
        // To remove
        ModalService,

        // Pipes
        LocalesPipe,
        CatalogLocalePipe,
        CurrencyPipe,

        // Guards
        AuthGuard,
        UnAuthGuard,
        PermissionGuard,

        // Interceptors
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ServerErrorInterceptor,
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ModalInterceptor, multi: true },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ReviveInterceptor,
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RefreshInterceptor,
          multi: true,
        },

        // Managers
        { provide: MODAL_MANAGER, useClass: ModalManager },
        { provide: NOTIFICATION_MANAGER, useClass: NotificationService },
        { provide: CACHE_MANAGER, useClass: CacheService },
        { provide: AUTH_MANAGER, useClass: AuthStoreService },

        // Sentry
        {
          provide: ErrorHandler,
          useValue: new ErrorHandlerService({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {
            // Empty factory
          },
          deps: [Sentry.TraceService],
          multi: true,
        },
      ],
    }
  }
}
