import { Pipe, PipeTransform } from '@angular/core'
import { PickingList } from '../picking-list.model'

@Pipe({
  name: 'missionPickingTime',
})
export class MissionPickingTime implements PipeTransform {
  transform(missionId: string, pickingList?: PickingList): string {
    if (!pickingList?.picks) {
      return ''
    }
    const sortedMissions = [...pickingList.picks].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    )

    const missionTimes: { missionId: string; timeTaken: string }[] = []
    if (sortedMissions.length) {
      sortedMissions.forEach((mission, index) => {
        let previousDate: Date
        if (index === 0) {
          previousDate = new Date(pickingList.statusHistory[1].date)
        } else {
          const previousMission = sortedMissions[index - 1]
          previousDate = new Date(previousMission.date)
        }

        const currentDate = new Date(mission.date)
        const timeTaken = currentDate.getTime() - previousDate.getTime()

        missionTimes.push({
          missionId: mission.missionId,
          timeTaken: this.transformTime(timeTaken),
        })
      })
    }
    return missionTimes.find((m) => m.missionId === missionId)?.timeTaken ?? ''
  }

  transformTime(ms: number): string {
    const totalMinutes = ms / (1000 * 60)
    const formattedMinutes = totalMinutes.toFixed(1)
    return `${formattedMinutes} min`
  }
}
