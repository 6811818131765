import { BulkUpdateMethod } from '../../models/bulk-update.model'
import { EntityStatusAuth, EntityStatusDesc } from '../../models/entity.model'
import { Field } from '../../models/field.model'
import {
  FilterCollection,
  FilterOperator,
  FilterType,
} from '../../models/filter.model'
import { SelectionData } from '../../models/util.model'
import { PermissionsScope } from '../policies/policy.model'
import {
  Coverage,
  OrderBulkUpdateField,
  OrderChangeStatusAction,
  OrderExportAmazonShipData,
  OrderField,
  OrderFieldGroup,
  OrderPermission,
  OrderStatus,
  OrderViewType,
} from './order.model'

export const ORDER_SCOPE: PermissionsScope = 'orders'
export const ORDER_PATH_KEY = 'order'
export const ORDER_PATH_PICKING_LIST = 'pickingListId'

export const ORDER_AMAZON_SHIP_EXPORT_DATA_INITIAL_STATE: OrderExportAmazonShipData =
  {
    channelId: undefined,
    startDate: new Date(),
    endDate: new Date(),
    email: '',
  }

export const ORDER_STATUSES: EntityStatusDesc<OrderStatus>[] = [
  {
    code: OrderStatus.drafted,
    name: 'Bozza',
    primary: true,
    default: true,
    tooltip:
      'NON impegna le quantità a magazzino e non è visibile per la gestione della spedizione.',
  },
  {
    code: OrderStatus.pending,
    name: 'In attesa',
    primary: true,
    default: true,
    tooltip:
      'Impegna le quantità a magazzino ma rimane in attesa di CONFERMA per poter essere gestito.',
  },
  {
    code: OrderStatus.confirmed,
    name: 'Confermato',
    primary: true,
    default: true,
    tooltip:
      'Ordine pronto per essere gestito per la spedizione. In caso di modifiche va rimesso IN ATTESA.',
  },
  {
    code: OrderStatus.processing,
    name: 'In lavorazione',
    primary: true,
    tooltip:
      'Ordine preso in carico da parte del magazzino. Non può essere modificato.',
  },
  {
    code: OrderStatus.processed,
    name: 'Processato',
    primary: true,
    tooltip:
      'Ordine già prelevato in attesa di essere imballato con stampa etichetta vettore. Quantità già scaricate dal magazzino.',
  },
  {
    code: OrderStatus.packed,
    name: 'Imballato',
    primary: true,
    tooltip: 'Ordine pronto per essere affidato al corriere.',
  },
  {
    code: OrderStatus.shipped,
    name: 'Spedito',
    primary: true,
    tooltip: 'Ordine affidato al corriere.',
  },
  {
    code: OrderStatus.delivered,
    name: 'Consegnato',
    primary: true,
    tooltip: 'Ordine consegnato da parte del corriere.',
  },
  {
    code: OrderStatus.parked,
    name: 'Ricevuto',
    primary: true,
    tooltip: 'Ordine parcheggiato in un pickup-point in attesa del cliente.',
  },
  {
    code: OrderStatus.handed,
    name: 'Ritirato',
    primary: true,
    tooltip: 'Ordine consegnato a mano al cliente.',
  },
  {
    code: OrderStatus.canceled,
    name: 'Annullato',
    primary: false,
    tooltip:
      'NON impegna le quantità a magazzino. ' +
      "L'ordine è definitivamente annullato e non è possibile effettuare ulteriori operazioni su di esso.",
  },
  {
    code: OrderStatus.dropshipped,
    name: 'Dropshipped',
    primary: false,
    tooltip: "L'ordine è gestito esternamente.",
  },
]

export const ORDER_ACTIONS: EntityStatusAuth<
  OrderChangeStatusAction,
  OrderPermission
> = {
  DRAFTED: {
    permissions: [
      'checkAddress',
      'checkRows',
      'deleteOrder',
      'editAlert',
      'editHeader',
      'editPayment',
      'editProducts',
      'editPromotion',
      'editRef',
      'saveOrder',
      'showProductQuantities',
    ],
    actions: [
      {
        label: 'Conferma ordine',
        action: 'confirm',
      },
      {
        label: 'Metti in attesa',
        action: 'pend',
      },
    ],
  },
  PENDING: {
    permissions: [
      'cancelOrder',
      'checkAddress',
      'checkCashOnDelivery',
      'checkRows',
      'dropShip',
      'editAlert',
      'editHeader',
      'editPayment',
      'editProducts',
      'editPromotion',
      'editRef',
      'printSummary',
      'saveOrder',
      'showProductQuantities',
      'splitOrder',
    ],
    actions: [
      {
        label: 'Conferma ordine',
        action: 'confirm',
      },
    ],
  },
  CONFIRMED: {
    permissions: [
      'cancelOrder',
      'checkAddress',
      'checkCashOnDelivery',
      'checkPickingList',
      'checkRows',
      'copyableRef',
      'dropShip',
      'editAlert',
      'editHeader',
      'editPayment',
      'editProducts',
      'editPromotion',
      'printSummary',
      'saveOrder',
      'showProductQuantities',
      'splitOrder',
      'pickOrder',
    ],
    actions: [
      {
        label: 'Metti in attesa',
        action: 'pend',
      },
    ],
  },
  PROCESSING: {
    permissions: [
      'checkCashOnDelivery',
      'checkPickingList',
      'checkPickError',
      'editAlert',
      'editCashOnDelivery',
      'editShipping',
      'packOrder',
      'pickUpProducts',
      'printSummary',
      'resetPicking',
      'saveOrder',
      'showProductQuantities',
    ],
    actions: [],
  },
  PROCESSED: {
    permissions: [
      'cancelOrder',
      'checkCashOnDelivery',
      'checkBordereau',
      'checkPackFinished',
      'editAlert',
      'editCashOnDelivery',
      'editPacking',
      'editShipping',
      'packOrder',
      'printSummary',
      'saveOrder',
      'showPackageInfo',
      'showPickedQuantities',
      'toggleAmountsEditable',
    ],
    actions: [
      {
        label: 'Segna come consegnato',
        action: 'deliver',
      },
    ],
  },
  PACKED: {
    permissions: [
      'cancelOrder',
      'cancelShipment',
      'editAlert',
      'printLabel',
      'printSummary',
      'saveOrder',
      'showPackageInfo',
      'showShipmentInfo',
      'checkBordereau',
      'showShippedQuantities',
      'toggleAmountsEditable',
    ],
    actions: [
      {
        label: 'Segna come spedito',
        action: 'ship',
      },
    ],
  },
  SHIPPED: {
    permissions: [
      'editAlert',
      'printSummary',
      'saveOrder',
      'printLabel',
      'showPackageInfo',
      'showShipmentInfo',
      'showShippedQuantities',
      'toggleAmountsEditable',
    ],
    actions: [
      {
        label: 'Segna come consegnato',
        action: 'deliver',
      },
      {
        label: 'Segna come ricevuto',
        action: 'park',
      },
    ],
  },
  DELIVERED: {
    permissions: [
      'editAlert',
      'printSummary',
      'saveOrder',
      'showPackageInfo',
      'showShipmentInfo',
      'showShippedQuantities',
      'toggleAmountsEditable',
    ],
    actions: [
      {
        label: 'Segna come ricevuto',
        action: 'park',
      },
      {
        label: 'Segna come ritirato',
        action: 'hand',
      },
    ],
  },
  PARKED: {
    permissions: [
      'editAlert',
      'printSummary',
      'saveOrder',
      'showPackageInfo',
      'showShipmentInfo',
      'showShippedQuantities',
      'toggleAmountsEditable',
    ],
    actions: [
      {
        label: 'Segna come ritirato',
        action: 'hand',
      },
    ],
  },
  HANDED: {
    permissions: [
      'editAlert',
      'printSummary',
      'saveOrder',
      'showPackageInfo',
      'showShipmentInfo',
      'showShippedQuantities',
      'toggleAmountsEditable',
    ],
    actions: [],
  },
  CANCELED: {
    permissions: ['editAlert', 'saveOrder'],
    actions: [],
  },
  DROPSHIPPED: {
    permissions: ['editAlert', 'printSummary', 'saveOrder'],
    actions: [
      {
        label: 'Riporta in confermato',
        action: 'confirm',
      },
    ],
  },
}

export const ORDER_FIELDS: Field<OrderField, OrderViewType>[] = [
  {
    label: 'Numero ordine',
    field: 'header.orderNumber',
    defaultViews: ['ORDERS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Data ordine',
    field: 'header.date',
    defaultViews: ['ORDERS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Magazzino assegnato',
    field: 'assignedWarehouseId',
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.warehouses,
  },
  {
    label: 'Riferimento data ordine',
    field: 'header.rifDate',
    defaultViews: ['ORDERS_LISTING_MAIN'],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Riferimento numero ordine',
    field: 'header.rifOrder',
    defaultViews: ['ORDERS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Riferimento esterno ordine',
    field: 'header.externalRifOrder',
  },
  {
    label: 'Tipologia fattura',
    field: 'header.invoiceType',
  },
  {
    label: 'Processato da',
    field: 'processedBy',
  },
  {
    label: 'Imballato da',
    field: 'packedBy',
  },
  {
    label: 'Tag',
    field: 'tags',
  },
  {
    label: 'Destinatario fatturazione',
    field: 'header.billingAddress.name',
    defaultViews: ['ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Indirizzo fatturazione',
    field: 'header.billingAddress.address1',
  },
  {
    label: 'Indirizzo 2 fatturazione',
    field: 'header.billingAddress.address2',
  },
  {
    label: 'Città fatturazione',
    field: 'header.billingAddress.city',
  },
  {
    label: 'Provincia fatturazione',
    field: 'header.billingAddress.province',
  },
  {
    label: 'Cap fatturazione',
    field: 'header.billingAddress.postalCode',
  },
  {
    label: 'Stato fatturazione',
    field: 'header.billingAddress.countryCode',
  },
  {
    label: 'Telefono fatturazione',
    field: 'header.billingAddress.phone',
  },
  {
    label: 'Email fatturazione',
    field: 'header.billingAddress.email',
  },
  {
    label: 'Canale di vendita',
    field: 'header.channel',
    defaultViews: ['ORDERS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.channels,
  },
  {
    label: 'Tipologia trasporto',
    field: 'header.transport',
  },
  {
    label: 'Corriere',
    field: 'carrierId',
    defaultViews: ['ORDERS_LISTING_MAIN'],
    bulkField: OrderBulkUpdateField.carrier,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.carriers,
  },
  {
    label: 'Destinatario destinazione',
    field: 'header.shippingAddress.name',
    defaultViews: ['ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Indirizzo destinazione',
    field: 'header.shippingAddress.address1',
  },
  {
    label: 'Indirizzo 2 destinazione',
    field: 'header.shippingAddress.address2',
  },
  {
    label: 'Città destinazione',
    field: 'header.shippingAddress.city',
  },
  {
    label: 'Provincia destinazione',
    field: 'header.shippingAddress.province',
  },
  {
    label: 'Cap destinazione',
    field: 'header.shippingAddress.postalCode',
  },
  {
    label: 'Stato destinazione',
    field: 'header.shippingAddress.countryCode',
  },
  {
    label: 'Telefono destinazione',
    field: 'header.shippingAddress.phone',
  },
  {
    label: 'Email destinazione',
    field: 'header.shippingAddress.email',
  },
  {
    label: 'Tipologia pagamento',
    field: 'header.paymentType',
    defaultViews: ['ORDERS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.payments,
  },
  {
    label: 'Valuta',
    field: 'header.currency',
  },
  {
    label: 'Fattura richiesta',
    field: 'header.requestInvoice',
  },
  {
    label: 'Pagato',
    field: 'header.paid',
  },
  {
    label: 'Importo contrassegno',
    field: 'header.cashOnDelivery.amount',
  },
  {
    label: 'Spedizione i.e.',
    field: 'header.shippingCost',
  },
  {
    label: 'Spedizione i.c.',
    field: 'header.shippingCostWithTaxes',
  },
  {
    label: 'IVA Spedizione',
    field: 'header.shippingTaxes',
  },
  {
    label: 'Subtotale i.e.',
    field: 'header.subTotal',
  },
  {
    label: 'Subtotale i.c.',
    field: 'header.subTotalWithTaxes',
  },
  {
    label: 'Tasse',
    field: 'header.bodyTaxes',
  },
  {
    label: 'Totale',
    field: 'header.totalOrderAmount',
    defaultViews: ['ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Data spedizione',
    field: 'header.shippingDate',
  },
  {
    label: 'Data imballo',
    field: 'packingDate',
  },
  {
    label: 'Numero colli',
    field: 'packagesCount',
  },
  {
    label: 'Numero righe',
    field: 'rowsCount',
  },
  {
    label: 'Numero pezzi',
    field: 'productsCount',
  },
  {
    label: 'Vol. colli (mc)',
    field: 'totalVolume',
  },
  {
    label: 'Peso colli (kg)',
    field: 'totalWeight',
  },
  {
    label: 'Stato',
    field: 'status',
    defaultViews: ['ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Note packing',
    field: 'header.packingNotes',
  },
  {
    label: 'Diviso',
    field: 'splitted',
  },
  {
    label: 'Duplicato',
    field: 'duplicated',
  },
  {
    label: 'Reso',
    field: 'returned',
  },
  {
    label: 'Volume stimato (mc)',
    field: 'estimatedVolume',
  },
  {
    label: 'Peso stimato (kg)',
    field: 'estimatedWeight',
  },
  {
    label: 'Gruppo',
    field: 'groupId',
  },
  {
    label: 'Alert ordine',
    field: 'header.importantNote',
  },
  {
    label: 'Picking list',
    field: 'pickingListId',
  },
  {
    label: 'Regalo',
    field: 'header.gift.isEnabled',
  },
  {
    label: 'Destinazione CEE',
    field: 'cee',
  },
  {
    label: 'Allegati',
    field: 'attachments',
    defaultViews: ['ORDERS_LISTING_MAIN'],
  },
  {
    label: 'Causale',
    field: 'header.reasonId',
    defaultViews: ['ORDERS_LISTING_MAIN'],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.reasons,
  },
]

export const ORDER_TRANSPORTS = [
  {
    code: 'SENDER',
    name: 'Mittente',
  },
  {
    code: 'RECIPIENT',
    name: 'Destinatario',
  },
  {
    code: 'CARRIER',
    name: 'Corriere',
  },
]

export const COVERAGE_TYPES: EntityStatusDesc<Coverage>[] = [
  {
    code: Coverage.available,
    name: 'Evadibile',
  },
  {
    code: Coverage.unavailable,
    name: 'Non evadibile',
  },
  {
    code: Coverage.partial,
    name: 'Parziale',
  },
  {
    code: Coverage.assigned,
    name: 'Assegnato',
  },
]

export const ORDER_FIELD_GROUPS: SelectionData<OrderFieldGroup>[] = [
  {
    value: OrderFieldGroup.carrier,
    label: 'Corriere',
    icon: 'fad fa-shipping-fast',
  },
]

export const ORDER_SYSTEM_TAGS: string[] = ['#customized', '#has-meta']
